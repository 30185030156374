import polygon from "../../assest/AayatTelecom/iphone/Apple iPhonebanner1.jpg";
import { Box, Button } from "@mui/material";
import "../../styles/AayatTelecom/AboutUsStyle.css";
import grayphoneImg from "../../assest/AayatTelecom/B.jpg";
import EnterpriseImg from "../../assest/AayatTelecom/A.jpg";
import AboutExpertiseData from "../../data/AayatTelecom/AboutExpertiseData";
import { Link } from "react-router-dom";
import verf1 from "../../assest/AayatTelecom/gsm.jpeg";
import verf2 from "../../assest/AayatTelecom/kadorf_cover.jpeg.jpg";

const AboutUs = () => {
  return (
    <>
      <Box className="Aboutus_topdiv">
        <img src={polygon} alt="img" />
        <div className="hosp_aboutushead">
          <h1>About Us</h1>
        </div>
      </Box>

      <Box className="Boost_about_div">
        <h1 className=" py-5">Leadership</h1>
        <Box className="Boost_about_div_home row">
          <div className="textdiv col-lg-6 col-12">
            <h6 className="">About us</h6>
            <p className="">
              Strategic Business Leader (Sales & Marketing) and over 25 years of
              extensive experience in entire functions of Business Development
              Operations, Sales & Marketing, Business Transformation, Strategic
              Sourcing & Procurement and Team Management.
            </p>
            <p className="">
              Change Management: Designing and communicating procurement
              solutions that drive improvement in top-line and bottom-line
              performance, implementing complex projects and delivering
              measurable results while establishing innovative strategic
              sourcing.
            </p>
            <p className="">
              Key strategist & implementer with proficiency in translating
              business vision and strategy into operational tactics; initiating
              & driving business transformation in a manner that improves
              operational execution; thrives a deadline-driven environments with
              excellent team-building skills yielding starkly improved outcomes
            </p>
            <p className="">
              Translated business needs into a sales productivity and readiness
              plan across after performing detailed needs assessment with
              experiencing in managing large territories, diverse teams and key
              accounts
            </p>
            <p className="">
              Worked with Brightstar, Brightpoint, Bharti Beetel, Distributed
              brands like O2, Apple, Motorola, HTC & Lenovo to name few.
            </p>

            {/* <p className=' fs-4'>At Aayat Telecom FZCO, a fully owned and operated distributor in DAFZA, we specialize in providing new iPhones in Dubai. With over 10 year of experience in the industry, we are committed to offering our valued customers genuine, top-quality products from renowned brands at unbeatable prices.</p> */}
          </div>
          <div className="imggdiv col-lg-6 col-12">
            <img src={grayphoneImg} alt="img" />
            <div className="selfDetail mt-3">
              <h3>Md Saif</h3>
              <p>Managing Director</p>
            </div>
          </div>
        </Box>
      </Box>

      <Box className="Boost_about_div">
        <Box className="Boost_about_div_home row">
          <div className="imggdiv col-lg-6 col-12">
            <img className="" src={EnterpriseImg} alt="img" />
            <div className="selfDetail mt-3">
              <h3>Shaik imran</h3>
              <p>Business Development Manager</p>
            </div>
          </div>
          <div className="textdiv col-lg-6 col-12">
            <h6 className="">Our Mission & Vission</h6>
            <p className=" fw-bold fs-4">Mission</p>
            <p className="pb-0 pt-1">
              To connect the world through top-quality mobile technology
              solutions, empowering individuals and businesses to stay connected
              and thrive in an increasingly digital world. We are committed to
              rigorous quality checks at every stage of our product sourcing and
              distribution process to ensure our customers receive the best
              possible mobile devices.
            </p>
            <p className=" fw-bold fs-4">Vission</p>
            <p className="pb-0 pt-1">
              Our aim is to deliver our customers both top-notch equipment and
              services while maintaining a competitive edge. We are dedicated to
              pursuing growth through innovative ideas, the introduction of new
              products, and forging robust partnerships. Our ongoing commitment
              involves enhancing our business processes to ensure swift and
              effective sales and post-sales services.
            </p>
          </div>
        </Box>
      </Box>

      <div className="Expertise_div">
        <h1 className=" fw-bold py-3">Numbers Speak For Themselves</h1>
        <div className="Expertise_slidediv">
          {AboutExpertiseData?.map((item, index) => (
            <div key={item.id} className="Expertise_div_slide">
              <img src={item.imgSrc} alt={`img-${item.id}`} />
              <h4>{item.heading}</h4>
              <p>{item.textdetail}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="GloballyRecognized">
        <h1>Globally Recognized</h1>
        <div className="Recognizeddiv row justify-content-center align-items-center">
          <div className=" col-lg-6 col-12">
            <img className="" src={verf1} alt="img" />
          </div>
          <div className=" col-lg-6 col-12">
            <img className="" src={verf2} alt="img" />
          </div>
        </div>

        {/* <div className="Recognizeddiv row justify-content-center align-items-center">
          <div className=" col-lg-6 col-12">
            <h5>VIP Plus handelot verified member</h5>
            <p>
              {" "}
              Handelot is a wholesale trading platform specifically designed for
              traders, distributors, e-tailers and retailers who are active in
              international trade of branded consumer electronics.{" "}
            </p>
          </div>
          <div className=" col-lg-6 col-12">
            <img className="" src={verf2} alt="img" />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default AboutUs;
